import React from "react"
import Layout from "../../components/layout"

function index() {
  return (
    <Layout>
      <div className="container flex items-center justify-center bg-white h-80 w-full">
        <h1 className="text-center text-2xl">
          "We are building this page. Stay tuned."
        </h1>
      </div>
    </Layout>
  )
}

export default index
